import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../utils/context";
import { compareStrings, createOrderedList } from "../../utils/other_utils";
import Form, { FormButtons, FormField } from "../../components/form/Form";
import Button from "../../components/form/Button";
import { CardStep } from "../Card";

function RolesForm({ roles, card }) {
  const { user, setUser } = useContext(UserContext);

  const [userRoles, setUserRoles] = useState(
    user[card] || {
      default_role: {},
    }
  );

  const allRoles = roles;

  useEffect(() => {
    updateRoles(userRoles);
  }, [userRoles]);

  function updateRoles(value) {
    setUser((prev) => {
      const update = {
        ...prev,
      };

      update[card] = value;

      return update;
    });
  }

  function getAllIndustries() {
    return Object.keys(allRoles);
  }

  function getAllIndustryRoles(industry) {
    if (allRoles[industry] && allRoles[industry]?.roles) {
      return Object.keys(allRoles[industry].roles);
    }

    return [];
  }

  function getIndustryRole(industry, role) {
    return allRoles[industry].roles[role];
  }

  function getAllIndustryRoleSpecialities(role) {
    if (!role?.speciality) {
      return [];
    }

    return Object.keys(role.speciality).sort(function (a, b) {
      const x = role.speciality[a];
      const y = role.speciality[b];
      return compareStrings(x.label, y.label);
    });
  }

  function getIndustryRoleSpeciality(industry, role, spec) {
    if (allRoles[industry]?.roles) {
      return allRoles[industry].roles[role].speciality[spec];
    } else if (allRoles[industry]?.speciality) {
      return allRoles[industry]?.speciality[spec];
    }
  }

  function getAddRoleButton() {
    if (Object.keys(userRoles).length < 5 && !userRoles.default_role) {
      return (
        <Button
          label="Add Another Role"
          role="accent"
          onClick={handleAddRole}
        />
      );
    }

    return null;
  }

  function handleAddRole(e) {
    e.preventDefault();

    setUserRoles((prev) => {
      const update = { ...prev };

      if (update.default_role) {
        delete update.default_role;
      }
      const roleId = Date.now();
      update[roleId] = {};

      return update;
    });
  }

  function getIndustryInput(roleId) {
    const ind = getAllIndustries();

    function handleOnChange({ target }, roleId) {
      const { value } = target;

      setUserRoles((prev) => {
        let update = { ...prev };

        if (value == "") {
          if (Object.keys(userRoles).length > 1) {
            update[roleId] = {};
          } else {
            update = {
              default_role: {},
            };
          }
          return update;
        }

        if (update.default_role) {
          roleId = Date.now();
          delete update.default_role;
        }

        update[roleId] = {};

        update[roleId].industry = {
          id: value,
        };

        return update;
      });
    }

    return (
      <select
        name="industry"
        value={userRoles[roleId]?.industry?.id || "--"}
        onChange={(e) => handleOnChange(e, roleId)}
      >
        <option key="--" value="--">
          -- Select --
        </option>
        {ind.map((industry) => (
          <option value={allRoles[industry].id} key={allRoles[industry].id}>
            {allRoles[industry].label}
          </option>
        ))}
      </select>
    );
  }

  function getRoleInput(roleId) {
    if (!userRoles[roleId]) {
      return null;
    }

    const { industry } = userRoles[roleId];

    if (!industry) {
      return null;
    }

    const role = industry?.role;

    const industryRoles = getAllIndustryRoles(industry.id);

    function handleOnChange({ target }, roleId) {
      const { value } = target;

      setUserRoles((prev) => {
        const update = { ...prev };

        if (!update[roleId].industry?.role) {
          update[roleId].industry.role = {};
        }

        update[roleId].industry.role = {
          id: value,
          specs: {},
        };

        return update;
      });
    }

    if (!industry.id || !industryRoles.length > 0) {
      return null;
    }

    const input = (
      <select
        value={userRoles[roleId].industry?.role?.id}
        onChange={(e) => handleOnChange(e, roleId)}
      >
        <option value="">-- Select --</option>
        {industry.id &&
          industryRoles.map((roleName) => (
            <option
              value={getIndustryRole(industry.id, roleName).id}
              key={getIndustryRole(industry.id, roleName).id}
            >
              {getIndustryRole(industry.id, roleName).label}
            </option>
          ))}
      </select>
    );

    return <FormField customkey={`label-${roleId}`} input={input} />;
  }

  function handleSpecialitySelection({ target }, roleId) {
    const spec = target.value;
    const isChecked = target.checked;

    setUserRoles((prev) => {
      const update = {
        ...prev,
      };

      var roleDetails = { ...update[roleId].industry };

      if (roleDetails.role) {
        roleDetails = roleDetails.role;
      }

      if (!roleDetails?.specs) {
        roleDetails.specs = {};
      }

      if (!isChecked) {
        delete roleDetails.specs[spec];

        if (Object.keys(roleDetails.specs).length == 0) {
          delete roleDetails.hideUnselectedSpecs;
        }

        if (update[roleId].industry?.role) {
          update[roleId].industry.role = roleDetails;
        } else {
          update[roleId].industry = roleDetails;
        }

        return update;
      }

      roleDetails.specs[spec] = {
        checked: isChecked,
        level: "0",
      };

      if (update[roleId].industry?.role) {
        update[roleId].industry.role = roleDetails;
      } else {
        update[roleId].industry = roleDetails;
      }

      return update;
    });
  }

  function isSpecChecked(roleId, spec) {
    if (userRoles[roleId]?.industry?.role?.specs) {
      return userRoles[roleId].industry.role.specs[spec]?.checked;
    } else if (userRoles[roleId]?.industry?.specs) {
      return userRoles[roleId].industry.specs[spec]?.checked;
    }

    return false;
  }

  function getSpecialityInput(spec, roleId) {
    const currentRole = userRoles[roleId];
    const { industry } = currentRole;
    const role = industry?.role;

    const isChecked = isSpecChecked(roleId, spec) || false;
    const hideUnselectedSpecs = currentRole?.hideUnselectedSpecs;

    if (hideUnselectedSpecs && !isChecked) {
      return null;
    }

    function handleLevelChange({ target }) {
      const { value } = target;

      setUserRoles((prev) => {
        const update = { ...prev };
        var roleDetails = { ...update[roleId].industry };

        if (roleDetails.role) {
          roleDetails = roleDetails.role;
        }

        roleDetails.specs[spec].level = value;

        if (update[roleId].industry?.role) {
          update[roleId].industry.role = roleDetails;
        } else {
          update[roleId].industry = roleDetails;
        }

        return update;
      });
    }

    var roleDetails = userRoles[roleId].industry;

    if (roleDetails?.role) {
      roleDetails = roleDetails.role;
    }

    const levelInput = (
      <select
        value={(roleDetails?.specs && roleDetails?.specs[spec]?.level) || "0"}
        name={`level`}
        onChange={handleLevelChange}
      >
        <option value="0">-- Level --</option>
        <option value="1">Beginner&nbsp;</option>
        <option value="2">Advanced&nbsp;</option>
        <option value="3">Expert&nbsp;</option>
      </select>
    );

    const checkboxId = userRoles[roleId].industry.id + "_" + spec;

    return (
      <div className="roles-card__specialities__item" key={checkboxId}>
        <div>
          <input
            type="checkbox"
            value={spec}
            id={checkboxId}
            checked={isChecked}
            onChange={(e) =>
              handleSpecialitySelection(e, roleId, industry.id, role?.id)
            }
          />
          <label htmlFor={checkboxId}>
            {` ${getIndustryRoleSpeciality(industry.id, role?.id, spec).label}`}
          </label>
        </div>
        {isChecked && levelInput}
      </div>
    );
  }

  function handleRemoveRole(e, roleId) {
    e.preventDefault();

    setUserRoles((prev) => {
      let update = { ...prev };

      if (update[roleId]) {
        delete update[roleId];
      }

      return update;
    });
  }

  function handleResetRole(e, roleId) {
    e.preventDefault();

    setUserRoles({ default_role: {} });
  }

  function getRemoveRoleButton(roleId) {
    if (Object.keys(userRoles).length > 1) {
      return (
        <a
          href="#"
          className="delete-role-button"
          onClick={(e) => handleRemoveRole(e, roleId)}
          key={`delete-${roleId}`}
        >
          [delete]
        </a>
      );
    } else {
      return (
        <a
          href="#"
          className="delete-role-button"
          onClick={(e) => handleResetRole(e, roleId)}
          key={`delete-${roleId}`}
        >
          [reset]
        </a>
      );
    }
  }

  function getRole(roleId, index) {
    const lastItem = index == Object.keys(userRoles).length - 1;
    const cardRoles = user?.[card];
    var specs = null;

    if (cardRoles && cardRoles[roleId] && roleId !== "default_role") {
      if (cardRoles[roleId]?.industry?.role) {
        specs = cardRoles[roleId]?.industry?.role?.specs;
      } else {
        specs = cardRoles[roleId]?.industry?.specs;
      }
    }

    function onFormSubmit(e) {
      e.preventDefault();
    }

    function toggleUnselectedSpecs() {
      setUserRoles((prev) => {
        const update = { ...prev };

        if ("hideUnselectedSpecs" in update[roleId]) {
          update[roleId] = {
            ...prev[roleId],
            hideUnselectedSpecs: !prev[roleId].hideUnselectedSpecs,
          };
        } else {
          update[roleId] = {
            ...prev[roleId],
            hideUnselectedSpecs: true,
          };
        }

        return update;
      });
    }

    const industryId = userRoles[roleId]?.industry?.id;
    var roleDetails = industryId && allRoles[userRoles[roleId]?.industry?.id];

    if (
      userRoles[roleId]?.industry?.role &&
      userRoles[roleId]?.industry?.role?.id
    ) {
      roleDetails =
        allRoles[industryId]?.roles[userRoles[roleId]?.industry?.role.id];
    }

    return (
      <Form
        className={`roles-card ${lastItem && "last-child"}`}
        onSubmit={onFormSubmit}
        key={`role-${index + 1}`}
      >
        <FormField
          key={`ind-${index + 1}`}
          label={[
            <span key={index + 1}>Role #{index + 1}</span>,
            getRemoveRoleButton(roleId),
          ]}
          input={getIndustryInput(roleId)}
        />

        {getRoleInput(roleId)}

        {(userRoles[roleId]?.industry?.role?.id || roleDetails?.speciality) && (
          <div className="roles-card__specialities">
            <div className="roles-card__specialities__title">
              Role:
              {specs && Object.keys(specs).length > 0 && (
                <Button
                  label={
                    userRoles[roleId]?.hideUnselectedSpecs
                      ? "Show Unselected"
                      : "Hide Unselected"
                  }
                  className="button--small"
                  role="subtle"
                  onClick={toggleUnselectedSpecs}
                />
              )}
            </div>
            {getAllIndustryRoleSpecialities(roleDetails).map((spec) =>
              getSpecialityInput(spec, roleId)
            )}
          </div>
        )}
      </Form>
    );
  }

  const rolesInOrder = createOrderedList(userRoles);

  return (
    <CardStep>
      {rolesInOrder.map((roleId, index) => getRole(roleId, index))}
      <br />
      <Form className="first-child">
        <FormButtons>{getAddRoleButton()}</FormButtons>
      </Form>
    </CardStep>
  );
}

export default RolesForm;
